import {reactive, toRefs, computed,defineComponent,onBeforeMount,onMounted,getCurrentInstance,provide,nextTick} from 'vue';
import OaApplyCardUtil,{IOaApplyCardDataObj} from './oaApplyCardUtil';
import FormalOrderList from "@/views/project/order/order/formalOrder/FormalOrderList.vue";
import FinanceUtils from "@/views/project/order/finance/financeUtils";
import ZxbCard from "@/views/project/order/finance/oa/zxb/ZxbCard.vue";
import PayDetail from "@/views/project/order/finance/common/payDetail/PayDetail.vue"
export default defineComponent({
    name:'OaApplyCard',
    title:'OA申请单',
    modelType:'card',
    fullscreen: true,
    components:{
        PayDetail
    },
    setup(){
        let {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        provide('EditTableOwner', proxy);
        let dataObj:IOaApplyCardDataObj=reactive<IOaApplyCardDataObj>({
            utilInst:{} as any,
            formRef:null,
            disabled:false,
            refMap:new Map(),
            //卡片传给dialog的初始化参数
            compParams: {
                hasDetails: true,
                detailInfos:{
                    detail:{},
                },
                details:['detail'],
                modelPath: utils.OrderProviderApi.buildUrl('/oaApply')
            },
            //表单
            form:{},
            //表单验证规则
            rules: {
                zxbId: [utils.UtilPub.commonValidRule(proxy.$t('oaApply.form.zxbId_rule'))],
                cyrq: [utils.UtilPub.commonValidRule(proxy.$t('oaApply.form.cyrq_rule'))],
                shdqr: [utils.UtilPub.commonValidRule(proxy.$t('oaApply.form.shdqr_rule'))]
            },
            otherParams:{
                cardFrom:'',//从哪个模块来
                oaZxbData:[],//中信保账户集合
                childParams:{
                    cardFrom:'oaApply',
                    gridTitle:'OA申请单明细',
                    urlPre:'oaApply',
                    submit:0//是否已经提交了，在下方会改变
                }
            }
        })
        onBeforeMount(()=>{
            dataObj.utilInst=new OaApplyCardUtil(proxy,dataObj);
        })
        onMounted(()=>{
            nextTick(async()=>{
                await dataObj.utilInst.buildSelectData();
            })
        })
        //---------------------------computed---------------------------
        //下拉select数据集获取（数据集构造和cascader类似）
        const comboSelect=computed(()=>{
            return (params:any) => {
                if(params && 'zxbId'==params.comboId){
                    return dataObj.otherParams.oaZxbData;
                }
            }
        })

        //---------------------------selectOnchange--------------------------
        const selectOnChange=(newValue:any,selectId:string)=>{
            if('zxbId'==selectId){
                dataObj.form.cyrq='';
                dataObj.form.shdqr='';
                dataObj.refMap.get('detail').clearDetailData();
                if(newValue){
                    dataObj.form.customerName=newValue.item.customerName;
                    dataObj.form.customerId=newValue.item.customerId;
                    dataObj.form.bz=newValue.item.bz;
                    dataObj.form.day=newValue.item.day;
                }else{
                    dataObj.form.customerName='';
                    dataObj.form.customerId='';
                    dataObj.form.bz='';
                    dataObj.form.day=0;
                }
            }
        }
        //日期选择改变事件
        const dateChange=(newValue:string,selectId:string)=>{
            if('cyrq'==selectId){//收汇到期日=出运日期+oa天数
                dataObj.form.shdqr=utils.UtilPub.formatDate(utils.UtilPub.getAfterDayDate(newValue,dataObj.form.day));
            }
        }
        //选择订单按钮事件
        const chooseOrder=async ()=>{
            if(!dataObj.form.zxbId){
                proxy.$message('请先选择中信保账户');
                return;
            }
            await utils.UtilPub.openDialog({customerId:dataObj.form.customerId,bz:dataObj.form.bz,proxy:proxy,modelComp:FormalOrderList,ownerComp:proxy,
                from:'oaApply',overflow:'hidden',title: '选择订单',contentHeight:'',showFooterBtn:true,fullscreen:true})
        }
        //选择订单弹出框确定事件
        const sureHandler=async (engineRef:any)=>{
            let selectRows=engineRef.pageListRef.getTbInst().getSelection();
            FinanceUtils.appendSelOrder(selectRows,dataObj.refMap.get('detail'));
            return true;
        }

        //查看中信保账户详情
        const zxbDetail=async (params:any)=>{
            await utils.UtilPub.openDialog({proxy:proxy,addOrEdit:'edit',id:params.value,modelComp:ZxbCard,ownerComp:proxy,cardFrom:'oaApplyCard',
                overflow:'hidden',showFooterBtn:false})
        }
        //打开弹出框的回调事件(已请求完/add或/load，data是返回值)
        const beforeOpen=async(res:any,addOrLoad:string,engineInst:any)=>{
            if('/load'==addOrLoad){
                dataObj.otherParams.childParams.submit=res.data.flag;
                await nextTick(()=>{
                    dataObj.form.zxbId=dataObj.otherParams.oaZxbData.find((item:any)=>item.value==dataObj.form.zxbId);
                    if(dataObj.form.zxbId){
                        dataObj.form.customerName=dataObj.form.zxbId.item.customerName;
                        dataObj.form.customerId=dataObj.form.zxbId.item.customerId;
                        dataObj.form.bz=dataObj.form.zxbId.item.bz;
                        dataObj.form.day=dataObj.form.zxbId.item.day;
                    }
                })
                if(res.data.flag==1){//已提交，页面禁用
                    dataObj.disabled=true;
                    dataObj.refMap.get('detail').setReadOnly(true);
                }
            }

            //记录从哪里进入信用证卡片
            dataObj.otherParams.cardForm=engineInst.engineParams.cardFrom;
        }
        //传入参数到后台
        const setParam=(engineInst:any)=>{
            return {cardFrom:engineInst.engineParams.cardFrom};
        }

        //保存之前，校验整个保存数据是否合法
        const beforeSaveHandler=()=>{
            //验证明细表格数据是否合法
            let detailData=dataObj.refMap.get('detail').getDetailData();
            let result=FinanceUtils.verifySaveData(detailData,proxy);
            if(result)dataObj.form.zxbId=dataObj.form.zxbId.value;
            return result;
        }
        //保存之后
        const afterSaveHandler=async (res:any)=>{
            //如果保存失败，需要把zxbId给找出来，否则会是一串数字，因为在保存之前已被改变
            if(!res.result)dataObj.form.zxbId=dataObj.otherParams.oaZxbData.find((item:any)=>item.value==dataObj.form.zxbId);
            return true;
        }

        //提交
        const submitHandler=async ()=>{
            await dataObj.utilInst.doSubmit();
        }
        return{
            ...toRefs(dataObj),proxy,comboSelect,selectOnChange,setParam,beforeOpen,beforeSaveHandler
         ,dateChange,chooseOrder,sureHandler,zxbDetail,afterSaveHandler,submitHandler
        }
    }
});