import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-67e9dc54"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "OaApply" }
const _hoisted_2 = { style: {"display":"flex","justify-content":"space-between","align-items":"center","width":"100%"} }
const _hoisted_3 = { style: {"flex":"1"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_date_picker = _resolveComponent("el-date-picker")!
  const _component_CardForm = _resolveComponent("CardForm")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_PayDetail = _resolveComponent("PayDetail")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_form, {
      ref: "formRef",
      model: _ctx.form,
      rules: _ctx.rules,
      "label-width": "100px"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_CardForm, {
          ownerInst: _ctx.proxy,
          formItems: _ctx.compParams.formItems,
          form: _ctx.form,
          formRef: _ctx.formRef,
          rules: _ctx.rules,
          disabled: _ctx.disabled,
          refMap: _ctx.refMap,
          colNum: 1
        }, {
          extendSlot: _withCtx(() => []),
          default: _withCtx(() => [
            _createVNode(_component_el_row, null, {
              default: _withCtx(() => [
                _createVNode(_component_el_col, { span: 12 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, {
                      label: "中信保账户",
                      prop: "zxbId"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_2, [
                          _createElementVNode("div", _hoisted_3, [
                            _createVNode(_component_el_select, {
                              ref: el=>_ctx.refMap.set('zxbId',el),
                              modelValue: _ctx.form.zxbId,
                              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.zxbId) = $event)),
                              "value-key": "value",
                              clearable: "",
                              placeholder: "请选择中信保账户",
                              style: {"width":"100%"},
                              disabled: _ctx.disabled,
                              onChange: _cache[1] || (_cache[1] = (val)=>{_ctx.selectOnChange(val,'zxbId')})
                            }, {
                              default: _withCtx(() => [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.comboSelect({comboId:'zxbId'}), (item) => {
                                  return (_openBlock(), _createBlock(_component_el_option, {
                                    key: item.value,
                                    label: item.label,
                                    value: item,
                                    disabled: item.disabled
                                  }, null, 8, ["label", "value", "disabled"]))
                                }), 128))
                              ]),
                              _: 1
                            }, 8, ["modelValue", "disabled"])
                          ]),
                          (_ctx.form.zxbId)
                            ? (_openBlock(), _createElementBlock("span", {
                                key: 0,
                                style: {"width":"40px","margin-left":"5px","font-size":"12px","cursor":"pointer","color":"dodgerblue"},
                                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.zxbDetail(_ctx.form.zxbId)))
                              }, "详情"))
                            : _createCommentVNode("", true)
                        ])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_col, { span: 12 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, {
                      label: "客户",
                      prop: "customerName"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.form.customerName), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_el_row, null, {
              default: _withCtx(() => [
                _createVNode(_component_el_col, { span: 12 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, {
                      label: "出运日期",
                      prop: "cyrq"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_date_picker, {
                          modelValue: _ctx.form.cyrq,
                          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.form.cyrq) = $event)),
                          placeholder: "请选择出运日期",
                          onChange: _cache[4] || (_cache[4] = (val)=>{_ctx.dateChange(val,'cyrq')}),
                          disabled: _ctx.disabled || !_ctx.form.zxbId
                        }, null, 8, ["modelValue", "disabled"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_col, { span: 12 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, {
                      label: "收汇到期日",
                      prop: "shdqr"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_date_picker, {
                          modelValue: _ctx.form.shdqr,
                          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.form.shdqr) = $event)),
                          placeholder: "请选择收汇到期日",
                          disabled: true
                        }, null, 8, ["modelValue"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["ownerInst", "formItems", "form", "formRef", "rules", "disabled", "refMap"])
      ]),
      _: 1
    }, 8, ["model", "rules"]),
    _createVNode(_component_PayDetail, {
      ownerInst: this,
      ownerOtherParams: _ctx.otherParams.childParams
    }, null, 8, ["ownerOtherParams"])
  ]))
}